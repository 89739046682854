import React, { useEffect } from "react";
import { NavbarTest } from "@gomycode/design-system";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { IProfileResponse } from "./app/services/profile";
import {
  logout,
} from "./Utils/helpers";
import Dashboard from "./Features/Dashboard";
import { routes } from "./Utils/routes";
import styles from "./Global.module.css";
import { useRefreshTheTokenMutation } from "./app/services/Global";
import "./index.css";
import {
  setRefreshToken,
  selectMustRefreshToken,
  setMustRefresh,
} from "./Features/Dashboard/dashboardSlice";
import jwt_decode from "jwt-decode";
import PhoneIcon from "./icons/PhoneIcon";
import LogoutIcon from "./icons/LogoutIcon";

function App() {
  const dispatch = useDispatch();
  const profile = useSelector(
    (state: { dashboard: IProfileResponse }) => state.dashboard.profile
  );
  const location = useSelector(
    (state: { dashboard: IProfileResponse }) => state.dashboard.location
  );

  const [refreshTheToken, { data }] = useRefreshTheTokenMutation();
  const mustRefresh = useSelector(selectMustRefreshToken);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const decodedToken: any = jwt_decode(
        String(localStorage.getItem("token"))
      );
      const expirationTime = decodedToken.exp * 1000; // convert expiration time to milliseconds
      const currentTime = Date.now();
      if (expirationTime > currentTime) {
        return null;
      } else {
        return dispatch(setMustRefresh<any>({}));
      }
    };
    if (localStorage?.getItem("token")) {
      checkTokenExpiration();
    }
    if (mustRefresh) {
      refreshTheToken({});
    }
    if (data) {
      dispatch(setRefreshToken(data));
    }
  }, [mustRefresh, refreshTheToken, dispatch, data]);

  if (process.env.hotjar__Key !== "false") {
    (function (hotjarKey: any) {
      const hj =
        (window as any).hj ||
        function () {
          (hj.q = hj.q || []).push(arguments);
        };
      hj("tagRecording", []);
      hj("trigger", "pageview");

      const head = document.head || document.getElementsByTagName("head")[0];
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = `https://static.hotjar.com/c/hotjar-${hotjarKey}.js?sv=6`;
      head.appendChild(script);
    })(process.env.hotjar__Key);
  }

  return (
    <div className={styles.salesGlobal}>
        <NavbarTest
        items={[
          {
            label: {
              title: 'Dashboard',
              link: routes.Dashboard,
              key: uuidv4(),
            },
            key: uuidv4(),
            type: 'simpleLabel',
          },
        ]}
        profile={{
          key: uuidv4(),
          picture: profile?.picture,
          title: profile?.fullName,
          role: 'Sales Agent',
          children: [
            {
              title: location?.name || "",
              key: uuidv4(),
              type: 'simpleLabel',
              link: '',
              rightIcon: <img
                src="https://gomycodelearn.blob.core.windows.net/assets/icons/topbar/ic_domain_24px.svg"
                alt="location icon"
                className={styles.addressIcon}
              />,
            },
            {
              title: profile?.phoneNumber ||"",
              key: uuidv4(),
              type: 'simpleLabel',
              link: '',
              rightIcon: <PhoneIcon className={styles.addressIcon} />,
            },
            {
              key: uuidv4(),
              title: 'Logout',
              rightIcon: <LogoutIcon />,
              onClick: () => logout(),
              type: 'labelLogout',
            },
          ],
        }}
      />

      <Routes>
        <Route path={routes.Dashboard} Component={Dashboard} />
        <Route
          path="*"
          element={<Navigate to={routes.Dashboard} replace={true} />}
        />
      </Routes>
    </div>
  );
}

export default App;
