import jwtDecode from "jwt-decode";
import parseUrl from "url-parse";

export const getCurrentQueryStringParameter = (
  param: string
): string | undefined => {
  const parsedUrl = parseUrl(window.location.href, true);
  return parsedUrl.query[param];
};

export const logout = () => {
  localStorage.clear();
  const currentUrl = window.location.origin + window.location.pathname;

  const redirectionUrl = process.env.REACT_APP_AUTH_METHOD === 'KEYCLOAK'
    ? `${process.env.REACT_APP_KEYCLOAK_URL}/logout?redirectUrl=${currentUrl}`
    : `${process.env.REACT_APP_AUTH_APP_URL}?logout=true`;
  window.location.href = redirectionUrl;
};
export const getRoles = () => {
  const token = localStorage.getItem('token');
  let data;
  if (token) { data = jwtDecode(token); }

  const roles = data && (data as { [key: string]: [] | string })['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

  return roles as string[];
};
export const roles = {
  sales: 'SALES_AGENT',
  instructor: 'INSTRUCTOR',
  operation_manager: 'OPERATION_MANAGER',
  assistant_operation_manager: 'ASSISTANT_OPERATION_MANAGER',
  central_operation_manager: 'COM',
  salesAgent: 'SALES_AGENT',
  student: 'STUDENT',
};

export const isSalesAgent = (apiRoles: string[]) => apiRoles?.includes(roles.salesAgent);


