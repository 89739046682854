import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const refreshTokenApi = createApi({
  reducerPath: 'refreshToken',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_LEARN_ENDPOINT,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");

      headers.set("X-Client-App", 'sales');
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      } else {
        // Redirection logic
      }

      return headers;
    },
  }),
  endpoints: builder => ({
    refreshTheToken: builder.mutation({
      query: data => ({
        url: '/auth/refresh-token',
        method: 'POST',
        body: {...data}
      })
    })
  })
});

export const { useRefreshTheTokenMutation } = refreshTokenApi;