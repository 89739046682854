import React from "react";
import styles from "./MobileTrackCard.module.css";
import { Card, OpeningCard } from "@gomycode/design-system";
import { makeDataSource } from "../tableHelper";
import dayjs from "dayjs";

type IMobileTrackCardProps = {
  availability: any;
  showModal: () => void,
  setSelectedAvailability: (el: any) => void
};

const MobileTrackCard: React.FC<IMobileTrackCardProps> = ({ availability, showModal, setSelectedAvailability }) => {

  const availableSeatsPerOpening = () => {
    return availability.openings.reduce((acc: number, el: any) => {
      return acc + el.opening.targetCapacity - el.opening.numberOfSubscribedStudents
    }, 0)
  };
  const makeOpeningCards = (availability: any) => {
    const result = makeDataSource(availability)

    return result.map(el => (
      <OpeningCard
        hasAvailableSpots={el.productSchedule.availableSeats > 0}
        openingName={el.productSchedule.productSchedule}
        onClick={() => {
          setSelectedAvailability(el)
          showModal()
        }}
        totalSpots={el.targetSeats}
        fieldSpots={el.subscribed}
        startDate={dayjs(el.startDate).format('MMMM DD, YYYY')}
        endDate={dayjs(el.endDate).format('MMMM DD, YYYY')}
        className={styles.openingCard}
      />
    )
    )
  }
  return (
    <Card className={styles.card}>
      <div className={styles.header}>
        <img
          className={styles.trackImage}
          src={availability.track.image}
          alt={availability.track.name}
        />
        <div>
          <h1 className={styles.seats}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.9375 2.875C5.64844 2.875 6.22266 2.30078 6.22266 1.5625C6.22266 0.851562 5.64844 0.25 4.9375 0.25C4.19922 0.25 3.625 0.851562 3.625 1.5625C3.625 2.30078 4.19922 2.875 4.9375 2.875ZM12.375 11.625H11.6641L10.543 8.69922C10.4336 8.37109 10.1055 8.125 9.75 8.125H7.04297L6.87891 7.25H9.06641C9.55859 7.25 9.94141 6.86719 9.94141 6.375C9.94141 5.91016 9.53125 5.5 9.06641 5.5H6.55078L6.44141 4.84375C6.30469 4.13281 5.62109 3.64062 4.91016 3.77734C4.19922 3.91406 3.70703 4.59766 3.84375 5.30859L4.47266 8.80859C4.63672 9.4375 5.18359 9.875 5.78516 9.875C5.8125 9.875 5.86719 9.875 5.92188 9.875C5.97656 9.875 6.00391 9.875 6.03125 9.875H9.12109L10.2422 12.8281C10.3516 13.1562 10.6797 13.375 11.0625 13.375H12.3477C12.8125 13.375 13.1953 12.9922 13.1953 12.5C13.1953 12.0352 12.8398 11.625 12.375 11.625ZM7.78125 10.75H5.34766C4.5 10.75 3.78906 10.1758 3.625 9.32812L2.72266 4.48828C2.64062 3.99609 2.17578 3.69531 1.71094 3.77734C1.21875 3.85938 0.917969 4.32422 1 4.78906L1.90234 9.65625C2.23047 11.3242 3.67969 12.5 5.34766 12.5H7.78125C8.24609 12.5 8.62891 12.1172 8.62891 11.625C8.62891 11.1602 8.24609 10.75 7.78125 10.75Z"
                fill="#828A92"
              />
            </svg>
            Available seats : {availableSeatsPerOpening()}
          </h1>
          <h1 className={styles.trackName}>{
            availability.track.name
          }</h1>
          <span className={styles.level}>#{availability.track.tag}</span>
        </div>
      </div>
      {
        makeOpeningCards(availability)
      }
    </Card>
  );
};

export default MobileTrackCard;
