import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getQueryString } from "../../constants";

export const externalServicesApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_LEARN_ENDPOINT,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      } else {
        // Redirection logic
      }

      return headers;
    },
  }),
  reducerPath: "externalServicesApi",
  endpoints: (builder) => ({
    getLocationsList: builder.query<any, any>({
      query: (params: any) => {
        const { query } = params;
        const queryString = getQueryString(query);
        return {
          url: "external-services/locations" + queryString,
          method: "GET",
        };
      },
    }),
  }),
});
export const { useGetLocationsListQuery } = externalServicesApi;
