import { configureStore } from "@reduxjs/toolkit";
import { profileApi } from "./services/profile";
import dashboardSlice from "../Features/Dashboard/dashboardSlice";
import { capacityApi } from "./services/capacity";
import { externalServicesApi } from "./services/externalServices";
import { refreshTokenApi } from "./services/Global";
export const store = configureStore({
  reducer: {
    [capacityApi.reducerPath]: capacityApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [externalServicesApi.reducerPath]: externalServicesApi.reducer,
    [refreshTokenApi.reducerPath]:refreshTokenApi.reducer,
    dashboard: dashboardSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
    .concat(refreshTokenApi.middleware)
      .concat(capacityApi.middleware)
      .concat(profileApi.middleware)
      .concat(externalServicesApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
