import React from 'react';

interface ILogoutIconProps {
  className?: string;
}

const LogoutIcon: React.FC<ILogoutIconProps> = ({ className }) => (
  <svg
    width="19"
    height="17"
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15.625 16.125H13.375C12.7773 16.125 12.25 15.6328 12.25 15C12.25 14.4023 12.7773 13.875 13.375 13.875H15.625C16.2578 13.875 16.75 13.3828 16.75 12.75V3.75C16.75 3.15234 16.2578 2.625 15.625 2.625H13.375C12.7773 2.625 12.25 2.13281 12.25 1.5C12.25 0.902344 12.7773 0.375 13.375 0.375H15.625C17.5234 0.375 19 1.88672 19 3.75V12.75C19 14.6133 17.5234 16.125 15.625 16.125ZM1.28125 7.65234L6.34375 2.87109C6.58984 2.625 6.94141 2.58984 7.25781 2.69531C7.57422 2.83594 7.75 3.15234 7.75 3.46875V6H12.25C12.8828 6 13.375 6.52734 13.375 7.125V9.375C13.375 10.0078 12.8828 10.5 12.25 10.5H7.75V13.0312C7.75 13.3477 7.57422 13.6641 7.25781 13.8047C6.94141 13.9102 6.58984 13.875 6.34375 13.6289L1.28125 8.84766C0.929688 8.56641 0.929688 7.96875 1.28125 7.65234Z"
      fill="#A5A5A5"
    />
  </svg>
);

export default LogoutIcon;

LogoutIcon.defaultProps = {
  className: '',
};
