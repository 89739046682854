import React from "react";
import styles from "./Modal.module.css";
import { Modal as ModalAnt, ModalProps } from "antd";
import { UserCard, Toast } from "@gomycode/design-system";
import { dateFormatter } from "../tableHelper";
import dayjs from "dayjs";
export interface IModalProps extends ModalProps {
  selectedAvailability: any;
}

const weekMap: any = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};
const Modal: React.FC<IModalProps> = ({
  open,
  selectedAvailability,
  onCancel,
}) => {
  
  return (
    <ModalAnt
      width={300}
      bodyStyle={{
        display: "flex",
        borderRadius: "10px",
        flexDirection: "column",
        // left: "10px",
      }}
      footer={null}
      open={open}
      onCancel={onCancel}
    >
      <span className={styles.modalTitle}>
        {selectedAvailability.trackName}
      </span>
      <div className={styles.rowContainer}>
        <span>Product schedule</span>
        <Toast
          type={selectedAvailability.availableSeats > 0 ? "success" : "failure"}
          withBorder={true}
          title={selectedAvailability.productSchedule.productSchedule}
        />
      </div>
      {selectedAvailability.timeslots.map((el: any) => {
        return (
          <span className={styles.trackStartDateStyles}>{`${
            weekMap[el.day]
          } ${el.startTime.slice(0, 5)} ${el.dueTime.slice(0, 5)}`}</span>
        );
      })}

      {/* <span className={styles.trackStartDateStyles}>Saturday 9:00 - 13:00</span> */}
      <div className={styles.dividerStyles}></div>
      <Toast
        type={selectedAvailability.availableSeats > 0 ? "success" : "failure"}
        withBorder={false}
        title={`Available seats : ${selectedAvailability.availableSeats}`}
        leftIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="14"
            data-name="Layer 1"
            viewBox="0 0 24 24"
          >
            <path d="M21 22H9a.99.99 0 01-.851-.474.989.989 0 01-.044-.974l.99-1.979a6.45 6.45 0 002.276.426H19.5c.734 0 1.429-.321 1.905-.881s.681-1.302.562-2.035c-.194-1.188-1.3-2.084-2.573-2.084h-8.009c-.674 0-1.27-.454-1.447-1.104L9.15 9.999H19a1 1 0 100-2H8.606L6.967 1.98C6.631.701 5.401-.153 4.162.023a2.505 2.505 0 00-1.723 1.095 2.504 2.504 0 00-.331 2.015l2.99 11.068a6.484 6.484 0 002.252 3.393l-1.033 2.065c-.468.938-.419 2.028.132 2.919s1.504 1.423 2.552 1.423h12a1 1 0 100-2z"></path>
          </svg>
        }
        width={200}
      />
      <Toast
        type="disabled"
        title={`Target seats : ${selectedAvailability.targetSeats}`}
        width={200}
        withBorder={false}
        leftIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="14"
            data-name="Layer 1"
            viewBox="0 0 24 24"
          >
            <path d="M24 12c0 6.62-5.38 12-12 12S0 18.62 0 12 5.38 0 12 0c.19 0 .38 0 .57.01A1.5 1.5 0 0114 1.58c-.04.83-.72 1.45-1.57 1.43-.14 0-.29-.01-.43-.01-4.96 0-9 4.04-9 9s4.04 9 9 9 9-4.04 9-9c0-.14 0-.29-.01-.43A1.5 1.5 0 0122.42 10c.85-.03 1.53.6 1.57 1.43 0 .19.01.38.01.57zM10.91 8.15c.8-.23 1.26-1.05 1.04-1.85s-1.06-1.26-1.85-1.04c-3 .85-5.09 3.62-5.09 6.74 0 3.86 3.14 7 7 7 3.12 0 5.89-2.09 6.74-5.09.23-.8-.24-1.63-1.04-1.85-.8-.23-1.63.24-1.85 1.04a4.017 4.017 0 01-3.85 2.91c-2.21 0-4-1.79-4-4 0-1.78 1.2-3.37 2.91-3.85zm.03 2.79a1.49 1.49 0 000 2.12c.29.29.68.44 1.06.44s.77-.15 1.06-.44L18.12 8h2.38c.4 0 .78-.16 1.06-.44l2-2A1.497 1.497 0 0022.5 3H21V1.5c0-.61-.37-1.15-.93-1.39-.56-.23-1.21-.1-1.63.33l-2 2c-.28.28-.44.66-.44 1.06v2.38l-5.06 5.06z"></path>
          </svg>
        }
        className={styles.toastStyles}
      />
      <Toast
        type="disabled"
        title={`Subscribed seats : ${selectedAvailability.subscribed}`}
        width={200}
        withBorder={false}
        className={styles.toastStyles}
        leftIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="14"
            data-name="Layer 1"
            viewBox="0 0 24 24"
          >
            <path d="M24 12c0 6.62-5.38 12-12 12S0 18.62 0 12 5.38 0 12 0c.19 0 .38 0 .57.01A1.5 1.5 0 0114 1.58c-.04.83-.72 1.45-1.57 1.43-.14 0-.29-.01-.43-.01-4.96 0-9 4.04-9 9s4.04 9 9 9 9-4.04 9-9c0-.14 0-.29-.01-.43A1.5 1.5 0 0122.42 10c.85-.03 1.53.6 1.57 1.43 0 .19.01.38.01.57zM10.91 8.15c.8-.23 1.26-1.05 1.04-1.85s-1.06-1.26-1.85-1.04c-3 .85-5.09 3.62-5.09 6.74 0 3.86 3.14 7 7 7 3.12 0 5.89-2.09 6.74-5.09.23-.8-.24-1.63-1.04-1.85-.8-.23-1.63.24-1.85 1.04a4.017 4.017 0 01-3.85 2.91c-2.21 0-4-1.79-4-4 0-1.78 1.2-3.37 2.91-3.85zm.03 2.79a1.49 1.49 0 000 2.12c.29.29.68.44 1.06.44s.77-.15 1.06-.44L18.12 8h2.38c.4 0 .78-.16 1.06-.44l2-2A1.497 1.497 0 0022.5 3H21V1.5c0-.61-.37-1.15-.93-1.39-.56-.23-1.21-.1-1.63.33l-2 2c-.28.28-.44.66-.44 1.06v2.38l-5.06 5.06z"></path>
          </svg>
        }
      />
      <div className={styles.spaceBetweenContainer}>
        <span className={styles.startDateText}>Start date</span>
        <span className={styles.startDateValue}>
          {dayjs(selectedAvailability.startDate).format('MMMM DD, YYYY')}{" "}
        </span>
      </div>
      <div className={styles.spaceBetweenContainer}>
        <span className={styles.startDateText}>End date</span>
        <span className={styles.startDateValue}>
          {dayjs(selectedAvailability.endDate).format('MMMM DD, YYYY')}
        </span>
      </div>
      <UserCard
        name={selectedAvailability.instructor.fullName}
        description="Instructor"
        image={selectedAvailability.instructor.picture}
        className={styles.userCardStyles}
      />
    </ModalAnt>
  );
};
export default Modal;
